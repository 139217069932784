<template>
  <div class="relatives-debug-icon">
    <ul class="arrow-menu" @click="isDebugFunction">
      <router-link
        :key="entry.title"
        v-for="entry in menu"
        tag="li"
        :to="entry.link"
        :class="{
          disabled: entry.disabled,
          locked: entry.locked,
        }"
      >
        <b-icon-lock-fill v-show="entry.locked" class="arrow-lock" />
        {{ entry.title }}
      </router-link>
    </ul>
    <div v-if="isDebug" class="debug-icon">
      <img src="../../public/images/debug.png" alt="" />
    </div>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
export default {
  props: {
    menu: Array,
  },
  service: null,

  created() {
    this.service = new AxiosService("Campaign");
    this.isDebugFunction();
  },
  data() {
    return {
      isDebug: null,
    };
  },
  methods: {
    isDebugFunction() {
      this.isDebug = null;
      this.service
        .readCustomEndpoint(
          "CampaignManager/IsDebugEnabledOnCampaign/" +
            this.$route.params.campaignId
        )
        .then((res) => {
          this.isDebug = res;
          console.log("IS DEBUG? ", this.isDebug);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.relatives-debug-icon {
  position: relative;
}

.debug-icon {
  position: absolute;
  top: 75px;
  right: 40px;
  font-weight: bold;
  color: red;
  font-size: 3rem;
  z-index: 1;
  img {
    width: 190px;
    height: auto;
  }
}
</style> 